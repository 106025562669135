import React from 'react'
import { Link } from 'gatsby'
import Logo from '../../content/assets/logo.svg';

import { rhythm } from '../utils/typography'

class Layout extends React.Component {
  render() {
    const { children } = this.props
    const header = (
      <div style={{ display: 'flex' }}>
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
            marginBottom: rhythm(-1),
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            <img height={35} src={Logo} className="App-logo" alt="logo" />
          </Link>
        </h3>
        <nav style={{ alignContent: 'flex-end', marginLeft: 'auto' }}>
          <Link to={'/about'}>About</Link>
        </nav>
      </div>
    )
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        {header}
        {children}
      </div>
    )
  }
}

export default Layout
